export default {
  owner_item_list: [
    // Relics
    15070, // Aegis
    18264, // Spharai
    18270, // Mandau
    18276, // Excalibur
    18282, // Ragnarok
    18288, // Guttler
    18294, // Bravura
    18300, // Gungnir
    18306, // Apocalypse
    18312, // Kikoku
    18318, // Amanomurakumo
    18324, // Mjollnir
    18330, // Claustrum
    18336, // Annihilator
    18342, // Gjallarhorn
    18348, // Yoichinoyumi
    // Mythics
    18989, // Terpischore
    18990, // Tupsimati
    18991, // Conqueror
    18992, // Glanzfaust
    18993, // Yagrush
    18994, // Laevateinn
    18995, // Murgleis
    18996, // Vajra
    18997, // Burtgang
    18998, // Liberator
    18999, // Aymur
    19000, // Carnwenhan
    19001, // Gastraphetes
    19002, // Kogarasumaru
    19003, // Nagi
    19004, // Ryunohige
    19005, // Nirvana
    19006, // Tizona
    19007, // Death Penalty
    19008, // Kenkonken
    // Miscellaneous
    17011, // Ebisu Fishing Rod
    13566, // Defending Ring
    15194, // Maat's Cap
  ],
};
