import React from 'react';

export default ({ WAR, MNK, WHM, BLM, RDM, THF, PLD, DRK, BST, BRD, RNG, SAM, NIN, DRG, SMN, BLU, COR, PUP, DNC, SCH }) => (
  <div className="eden_player-jobs">
    <h6>Jobs</h6>
    <table>
      <tbody>
        <tr>
          <td>WAR:</td>
          <td>{WAR}</td>
          <td>MNK:</td>
          <td>{MNK}</td>
        </tr>
        <tr>
          <td>WHM:</td>
          <td>{WHM}</td>
          <td>BLM:</td>
          <td>{BLM}</td>
        </tr>
        <tr>
          <td>RDM:</td>
          <td>{RDM}</td>
          <td>THF:</td>
          <td>{THF}</td>
        </tr>
        <tr>
          <td>PLD:</td>
          <td>{PLD}</td>
          <td>DRK:</td>
          <td>{DRK}</td>
        </tr>
        <tr>
          <td>BST:</td>
          <td>{BST}</td>
          <td>BRD:</td>
          <td>{BRD}</td>
        </tr>
        <tr>
          <td>RNG:</td>
          <td>{RNG}</td>
          <td>SAM:</td>
          <td>{SAM}</td>
        </tr>
        <tr>
          <td>NIN:</td>
          <td>{NIN}</td>
          <td>DRG:</td>
          <td>{DRG}</td>
        </tr>
        <tr>
          <td>SMN:</td>
          <td>{SMN}</td>
          <td>BLU:</td>
          <td>{BLU}</td>
        </tr>
        <tr>
          <td>COR:</td>
          <td>{COR}</td>
          <td>PUP:</td>
          <td>{PUP}</td>
        </tr>
        <tr>
          <td>DNC:</td>
          <td>{DNC}</td>
          <td>SCH:</td>
          <td>{SCH}</td>
        </tr>
      </tbody>
    </table>
  </div>
);
